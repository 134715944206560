// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormControl, FormHelperText, MenuItem, Select, Typography } from '@mui/material';
//OutlinedInput
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
//useState
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import { readCurrentDeviceState } from '../../../store/appSlice';
import { getCurrentJoinCompetitionData, setJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import JoinCompetitionButtons from './JoinCompetitionButtons';

type CompetitionJoinNicknameScreenProps = {};

const CompetitionJoinNicknameScreen: React.FC<CompetitionJoinNicknameScreenProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDesktop = useSelector(readCurrentDeviceState);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      team: '',
      newTeamName: '',
    },
  });

  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  // const [isAddingTeam, setIsAddingTeam] = useState<boolean>(false);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaninger');
    }
  }, [currentJoinCompetitionData, navigate, setValue]);

  /**
   *
   *
   * Validates and submits the form
   *
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const team = data.newTeamName ? DOMPurify.sanitize(data.newTeamName) : data.team || null;

    if (data.team === '' && (data.newTeamName === '' || data.newTeamName === undefined)) {
      setError('team', {
        type: 'manual',
        message: t('error_select_team') || 'Error',
      });
    } else {
      dispatch(
        setJoinCompetitionData({
          currentJoinCompetitionData: {
            ...currentJoinCompetitionData,
            team: team,
            newTeamAdded: data.newTeamName === '' || data.newTeamName === undefined ? false : true,
          },
        }),
      );
      navigate('/app/utmaning-smeknamn');
    }
  };

  return (
    <>
      <TopBar position="fixed" hasBackButton />

      <MainContainer contentMaxWidth={600}>
        <Box sx={{ mt: 22 }}>
          <Typography variant="h6" component="p" mt={4} mb={6} fontWeight={600}>
            {t('heading_choose_team')}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {currentJoinCompetitionData?.teams?.length > 0 && (
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                {/* disabled={isAddingTeam} */}
                <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="duration-select">
                  {t('textfield_label_choose_team')}
                </label>

                <Controller
                  name="team"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        sx={{ bgcolor: 'background.paper' }}
                        placeholder="Lagnamn"
                        labelId="duration-label"
                        id="duration-select"
                        error={!!errors.team}
                        data-testid="select-team"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Lagnamn
                        </MenuItem>
                        {currentJoinCompetitionData?.teams.map((team: any, index: number) => (
                          <MenuItem key={index} value={team} data-testid={`menu-item-${index}`}>
                            {team}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.team && typeof errors.team.message === 'string' && (
                        <FormHelperText sx={{ ml: 0 }} error={true}>
                          {errors.team.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
            )}
            {/* {isAddingTeam ? (
              <>
                <FormControl variant="outlined" fullWidth sx={{ mt: 4 }}>
                  <Controller
                    control={control}
                    name="newTeamName"
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name } }) => (
                      <>
                        <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                          {t('label_new_team_join_competition')}
                        </label>
                        <OutlinedInput
                          data-testid="input-join-competition-new-team-name"
                          sx={{ bgcolor: 'background.paper' }}
                          value={value}
                          onChange={onChange}
                          name={name}
                          type="text"
                          required={true}
                          id={name}
                          autoComplete="off"
                          error={!!errors.newTeamName}
                        />
                        {errors.newTeamName && typeof errors.newTeamName.message === 'string' && (
                          <FormHelperText sx={{ ml: 0 }} error={true}>
                            {errors.newTeamName.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>
                <Button
                  data-testid="button-regret-team"
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{ mt: 3 }}
                  onClick={() => {
                    setValue('newTeamName', '');
                    setIsAddingTeam(false);
                  }}
                >
                  {t('button_regret_team')}
                </Button>
              </>
            ) : (
              
              <Button
                data-testid="button-add-team"
                variant="outlined"
                color="primary"
                size="small"
                sx={{ mt: 3 }}
                onClick={() => setIsAddingTeam(true)}
              >
                {t('button_create_new_team')}
              </Button>
            )} */}
            <Box sx={{ mt: 8 }}>
              <JoinCompetitionButtons isDesktop={isDesktop} showSkipButton={false} />
            </Box>
          </form>
        </Box>
      </MainContainer>
    </>
  );
};

export default CompetitionJoinNicknameScreen;

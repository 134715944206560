import {
  DocumentData,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { AssignmentResult } from '../../utilities/types';
import { db } from '../firebaseConfig';

/**
 *
 * fetch results from a single user
 *
 */
export const getResultsFirebase = async (userId: string): Promise<DocumentData | undefined> => {
  const resultsSnap = await getDoc(doc(db, 'results', userId));

  if (resultsSnap?.exists() && resultsSnap.data()) {
    return resultsSnap.data();
  }

  return undefined;
};

/**
 *
 *
 *
 *
 */
export const getResultsCompetitionsFirebase = async (competitionId: string): Promise<DocumentData | undefined> => {
  const queryResults = query(collection(db, 'results'), where('competitionId', '==', competitionId));

  const querySnapshotResults = await getDocs(queryResults);

  if (!querySnapshotResults.empty) {
    return querySnapshotResults;
  }
  return undefined;
};

/**
 *
 * Set custom assignment
 *
 */
type NewUserData = {
  userId: string;
  results: WithFieldValue<DocumentData>;
};

export const setUserResultsFirebase = async ({ userId, results }: NewUserData): Promise<string> => {
  await setDoc(doc(db, 'results', userId), results);

  return 'user-result-set';
};

/**
 *
 * Updating existing Results (assignments)
 *
 */
type AssignmentsResultsData = {
  userId: string;
  status?: string;
  newAssignmentsResults: AssignmentResult[];
};

export const updateAssignmentsResultsFirebase = async ({
  userId,
  status,
  newAssignmentsResults,
}: AssignmentsResultsData): Promise<string> => {
  const userResultDoc = doc(db, 'results', userId);

  if (status) {
    await updateDoc(userResultDoc, {
      assignmentsResults: newAssignmentsResults,
      status: status,
    });
  } else {
    await updateDoc(userResultDoc, {
      assignmentsResults: newAssignmentsResults,
    });
  }

  return 'user-results-updated';
};

/**
 *
 * Updating existing Results (Badges)
 *
 */
type BadgesResultsData = {
  userId: string;
  badges: any;
};

export const updateBadgesResultsFirebase = async ({ userId, badges }: BadgesResultsData): Promise<string> => {
  const userResultDoc = doc(db, 'results', userId);

  await updateDoc(userResultDoc, {
    badges: badges,
  });

  return 'user-badges-updated';
};

/**
 *
 * Updating existing Results with isAnonymous
 *
 */
type AnonymousResultsData = {
  userId: string;
  isAnonymous: boolean;
};

export const updateIsAnonymousResultsFirebase = async ({
  userId,
  isAnonymous,
}: AnonymousResultsData): Promise<string> => {
  const userResultDoc = doc(db, 'results', userId);

  await updateDoc(userResultDoc, {
    isAnonymous: isAnonymous,
  });

  return 'user-results-updated';
};

import { useDispatch } from 'react-redux';

import { unsetCompetition } from '../../../store/competitionSlice';
import { unsetCustomAssignment } from '../../../store/customAssignmentSlice';
import { unsetDays } from '../../../store/daysHandlerSlice';
import { unsetResults } from '../../../store/resultsSlice';

const useReset = () => {
  const dispatch = useDispatch();

  const reset = () => {
    dispatch(unsetCompetition());
    dispatch(unsetCustomAssignment());
    dispatch(unsetDays());
    dispatch(unsetResults());
  };

  return { reset };
};

export default useReset;

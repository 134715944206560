import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { User, onAuthStateChanged } from 'firebase/auth';
import { DocumentData } from 'firebase/firestore';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './App.css';
import BottomBarNavigation from './components/BottomBarNavigation';
import Loader from './components/Loader';
import SettingsDrawer from './components/SettingsDrawer';
import SideBarNavigation from './components/SideBarNavigation';
import Snackybar from './components/Snackybar';
import { getCustomAssignmentFirebase } from './firebase/db/assignments';
import { getCompetitionFirebase } from './firebase/db/competitions';
import { getResultsFirebase } from './firebase/db/results';
import { getUserFirebase, setUserFirebase } from './firebase/db/users';
import { auth } from './firebase/firebaseConfig';
import AppRoutes from './routing/AppRoutes';
import { readCurrentDarkModeState, readCurrentErrorState, setIsDesktop, setIsIPhone } from './store/appSlice';
import { readCurrentDeviceState } from './store/appSlice';
import { setErrorMsg } from './store/appSlice';
import { setCompetition } from './store/competitionSlice';
import { setCustomAssignment } from './store/customAssignmentSlice';
import { setDays } from './store/daysHandlerSlice';
import { setResults } from './store/resultsSlice';
import { logout, setUser } from './store/userSlice';
import { darkTheme, lightTheme } from './theme/theme';
import { ScrollToTop } from './utilities/functionality/ScrollHandlers';
import { Competition, Results } from './utilities/types';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const matches = useMediaQuery('(min-width:768px)');
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);
  const error = useSelector(readCurrentErrorState);

  const dispatch = useDispatch();

  /**
   *
   *
   *
   */
  const saveUserToRedux = useCallback(
    (_user: DocumentData | string | undefined, _fireUser: { uid: any; email: any }) => {
      dispatch(
        setUser({
          uid: _fireUser.uid,
          email: _fireUser.email,
          role: typeof _user === 'object' ? _user.role : _user,
        }),
      );
    },
    [dispatch],
  );

  /**
   *
   *
   *
   */
  const saveResultsToRedux = useCallback(
    (_results: Results) => {
      dispatch(setResults({ currentResults: _results }));
    },
    [dispatch],
  );

  /**
   *
   *
   *
   */
  const saveCompetitionToRedux = useCallback(
    (competition: Competition) => {
      dispatch(setCompetition({ currentCompetition: competition }));
    },
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUserResultsEtc = async (_user: any, _fireUser: any, dispatch: any) => {
    saveUserToRedux(_user, _fireUser);

    const _results = await getResultsFirebase(_fireUser.uid);
    if (_results) {
      saveResultsToRedux(_results as Results);

      const lastDay = _results.assignmentsResults[0]?.points?.length - 1;
      dispatch(setDays({ activeDayIndex: lastDay, lastDayIndex: lastDay }));

      const _competition = await getCompetitionFirebase(_results.competitionId);
      _competition && saveCompetitionToRedux(_competition as Competition);

      const customAssignmentSnap = await getCustomAssignmentFirebase(_fireUser.uid);
      customAssignmentSnap &&
        dispatch(
          setCustomAssignment({
            uid: customAssignmentSnap.uid,
            competitionId: customAssignmentSnap.competitionId,
            title: customAssignmentSnap.title,
          }),
        );
    }
  };

  /**
   *
   *
   *
   */
  const handleNewUser = useCallback(
    async (_fireUser: User, dispatch: Dispatch<AnyAction>, setIsLoading: (value: boolean) => void) => {
      try {
        const _user = await getUserFirebase(_fireUser.uid);
        if (_user) {
          await handleUserResultsEtc(_user, _fireUser, dispatch);
        } else {
          await setUserFirebase({ userId: _fireUser.uid, email: _fireUser.email, role: 'user' });
          saveUserToRedux('user', _fireUser);
        }
      } catch (e) {
        dispatch(logout());
      } finally {
        setIsLoading(false);
      }
    },
    [handleUserResultsEtc, saveUserToRedux],
  );

  /**
   *
   *
   *
   */
  const handleExistingUser = useCallback(
    async (fireUser: User, dispatch: Dispatch<AnyAction>, setIsLoading: (value: boolean) => void) => {
      try {
        const user = await getUserFirebase(fireUser.uid);

        if (user) {
          await handleUserResultsEtc(user, fireUser, dispatch);
        } else {
          dispatch(logout());
        }
      } catch (e) {
        dispatch(logout());
      } finally {
        setIsLoading(false);
      }
    },
    [handleUserResultsEtc],
  );

  const checkIsIphone = () => {
    return /iPhone/.test(navigator.userAgent);
  };

  useEffect(() => {
    const chosenLanguage = getChosenLanguage(); // Replace with your language selection logic

    i18next.changeLanguage(chosenLanguage, (err, t) => {
      if (err) {
        console.error('Something went wrong loading', err);
      }
      t('key');
    });

    dispatch(setIsDesktop(matches));

    const isIphone = checkIsIphone();
    dispatch(setIsIPhone(isIphone));

    // Listen to signup or login
    onAuthStateChanged(auth, async (fireUser) => {
      // If signup or login is successful
      if (fireUser) {
        const lastSignInTime = fireUser.metadata.lastSignInTime;
        const creationTime = fireUser.metadata.creationTime;
        if (creationTime === lastSignInTime) {
          handleNewUser(fireUser, dispatch, setIsLoading);
        } else {
          handleExistingUser(fireUser, dispatch, setIsLoading);
        }
      } else {
        dispatch(logout());
        setIsLoading(false);
      }
    });
  }, [dispatch, handleExistingUser, handleNewUser, setIsLoading, matches]);

  /**
   * Sets initial language
   *
   */
  const getChosenLanguage = (): string => {
    const langFromLocalStorage = localStorage.getItem('lang');
    if (langFromLocalStorage !== null) {
      return langFromLocalStorage;
    }

    localStorage.setItem('lang', 'se');
    return 'se';
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Snackybar
        dataTestid="snackbar-error"
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => dispatch(setErrorMsg(null))}
        severity="error"
        text={error}
      />
      <div data-testid="app">
        <ScrollToTop />

        <CssBaseline />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <AppRoutes />

            {isDesktop ? <SideBarNavigation /> : <BottomBarNavigation />}
          </>
        )}
      </div>
      <SettingsDrawer />
    </ThemeProvider>
  );
};

export default App;

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';

import MainContainer from '../../components/MainContainer';
import SimpleCard from '../../components/SimpleCard';
import TopBar from '../../components/TopBar';
import DialogDailyGoal from '../../components/dialogs/DialogDailyGoal';
import { readCurrentDeviceState, setShowSettingsDrawer } from '../../store/appSlice';
import { getCurrentCompetition } from '../../store/competitionSlice';
import { getCurrentResults } from '../../store/resultsSlice';
import AchievementsBadgesPoints from './achievementsScreen/AchievementsBadges';
import AchievementsGoalCard from './achievementsScreen/AchievementsGoalCard';
import AchievementsPointsCard from './achievementsScreen/AchievementsPointsCard';
import AchievementsProgress from './achievementsScreen/AchievementsProgress';
import AchievementsStreak from './achievementsScreen/AchievementsStreak';

type AchievementsScreenProps = {};

const AchievementsScreen: React.FC<AchievementsScreenProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDesktop = useSelector(readCurrentDeviceState);
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);

  const [displayGoalDialog, setDisplayGoalDialog] = useState<boolean>(false);

  /**
   *
   *
   */

  return (
    <>
      {currentCompetition ? (
        <>
          <DialogDailyGoal
            title=""
            content=""
            displayDialog={displayGoalDialog}
            setDisplayDialog={setDisplayGoalDialog}
            callback={() => {
              setDisplayGoalDialog(false);
            }}
          />

          <TopBar
            title={t('title_achievements')}
            position="static"
            rightAction={
              !isDesktop && (
                <Box sx={{ marginRight: 2 }}>
                  <IconButton
                    edge="start"
                    data-testid="button-settings"
                    aria-label="settings"
                    onClick={() => {
                      dispatch(setShowSettingsDrawer(true));
                    }}
                    sx={{
                      color: 'text.primary',
                    }}
                  >
                    <MenuRoundedIcon fontSize="medium" />
                  </IconButton>
                </Box>
              )
            }
          />

          <MainContainer style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 8, marginBottom: 100 }}>
            <Box display="flex" gap={2}>
              <AchievementsPointsCard />

              <AchievementsGoalCard
                hasGoal={Boolean(currentResults?.goal)}
                setDisplayGoalDialog={setDisplayGoalDialog}
              />
            </Box>
            {currentCompetition?.duration > 0 && <AchievementsProgress duration={currentCompetition.duration} />}

            <SimpleCard>
              <AchievementsStreak />
            </SimpleCard>
            <SimpleCard
              styles={{
                display: 'flex',
                flexDirection: 'column',

                p: 3,
              }}
            >
              <>
                <Box mt={4}>
                  <Typography data-testid="text-heading-badges" variant="h6" sx={{ pl: 4, mt: -2 }}>
                    {t('heading_badges')}
                  </Typography>
                  <Typography variant="caption" sx={{ pl: 4, mt: -2 }}>
                    {t('label_badges')}
                  </Typography>
                </Box>
                <AchievementsBadgesPoints />
              </>
            </SimpleCard>
          </MainContainer>
        </>
      ) : (
        <Navigate to="/app/utmaningar" replace />
      )}
    </>
  );
};

export default AchievementsScreen;

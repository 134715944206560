import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { DocumentData } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import SimpleCard from '../../components/SimpleCard';
import SortableTable from '../../components/SortableTable';
import { addAssignmentFirebase, getAssignmentsFirebase } from '../../firebase/db/assignments';
import { getCompetitionsFirebase, updateCompetitionTeamsFirebase } from '../../firebase/db/competitions';
import { getResultsCompetitionsFirebase } from '../../firebase/db/results';
import { getScoresCompetitionFirebase } from '../../firebase/db/scores';
import { readCurrentDarkModeState, readCurrentDeviceState, setErrorMsg } from '../../store/appSlice';
import { getCurrentUser } from '../../store/userSlice';
import { assignments } from '../../utilities/objects/AssignmentsObject';
import { AssignmentResult, Competition } from '../../utilities/types';
import ScoresPanel from './AdminComponents/ScoresPanel';

interface UserData {
  user: string;
  points: number;
  days: number;
  team: string;
}

const AdminScreen = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'en' ? 'en' : 'se';
  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [usersTeamData, setUsersTeamData] = useState<UserData[]>([]);

  const [selectedCompetitionId, setSelectedCompetitionId] = useState<string>('');
  const [selectedCompetitionData, setSelectedCompetitionData] = useState<Competition | DocumentData>();

  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [teamsForCompetition, setTeamsForCompetition] = useState<string[] | null>(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [showCreateNewTeam, setShowCreateNewTeam] = React.useState(false);
  const [competitions, setCompetitions] = useState<Array<Competition | DocumentData>>([]);
  const [fetchedAssignments, setFetchedAssignments] = useState<[]>([]);
  const [resultsBasedOnAssignment, setResultsBasedOnAssignment] = useState<{}>({});

  const handleChangeTab = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);

  const fetchAssignments = useCallback(async () => {
    const fetchedAssignments = await getAssignmentsFirebase();

    let assignments: any = {};

    fetchedAssignments?.forEach((doc) => {
      const assignmentData = doc.data();

      assignments[doc.id] = {
        id: doc.id,
        ...assignmentData,
      };
    });

    setFetchedAssignments(assignments);
  }, []);

  /**
   * Fetch competition data
   */
  useEffect(() => {
    async function getCompetitionData() {
      try {
        if (currentUser?.role !== 'admin') {
          navigate('/app', { replace: true });
        }

        fetchAssignments();
        const queryCompetitionsSnapshot = await getCompetitionsFirebase();

        let _competitions: DocumentData[] = [];

        queryCompetitionsSnapshot?.forEach((doc) => {
          _competitions.push({ competitionId: doc.id, ...(doc.data() as Competition | DocumentData) });
        });

        setCompetitions(_competitions);
        setSelectedCompetitionId(_competitions[0].competitionId);
      } catch (e) {}
    }
    getCompetitionData();
  }, [currentUser, fetchAssignments, navigate]);

  /**
   *  Handle team change
   * @param event
   */
  const handleTeamChange = (event: SelectChangeEvent<string>) => {
    const teamName = event.target.value as string;
    setSelectedTeam(teamName);
    setUsersTeamData(usersData.filter((user) => user.team === teamName));
  };

  /**
   * Handle competition change
   */
  const handleCompetitionChange = (event: SelectChangeEvent<string>) => {
    const competitionId = event.target.value as string;
    setSelectedCompetitionId(competitionId);
  };

  /**
   *
   * Fetch userData for competition
   *
   */
  const getUsersData = useCallback(async () => {
    if (!selectedCompetitionId) return;

    const scoresSnapshot = await getScoresCompetitionFirebase(selectedCompetitionId);

    let _usersData: UserData[] = [];

    scoresSnapshot?.forEach((doc) => {
      const scoresData: DocumentData = doc.data();
      const user = scoresData.nickName ?? '';
      const points = scoresData.score ?? 0;
      const days = scoresData.nrOfDaysSubmitted ?? 0;
      const team = scoresData.team;

      _usersData.push({ user, points, days, team });
    });

    const _results: DocumentData | undefined = await getResultsCompetitionsFirebase(selectedCompetitionId);

    let usersResultsData: any = {};

    _results?.forEach((doc: DocumentData) => {
      const resultsData: DocumentData = doc.data();

      const _usersResultsData = resultsData.assignmentsResults.map((result: AssignmentResult) => {
        return {
          id: result.id,
          sumPoints: result.points.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0),
          type: result.type,
        };
      });

      _usersResultsData.forEach((result: any) => {
        if (result.type === 'custom') {
          if (usersResultsData.custom) {
            usersResultsData.custom = usersResultsData.custom += result.sumPoints;
          } else {
            usersResultsData.custom = result.sumPoints;
          }
        } else if (usersResultsData[result.id]) {
          usersResultsData[result.id] = usersResultsData[result.id] += result.sumPoints;
        } else {
          usersResultsData[result.id] = result.sumPoints;
        }
      });
    });

    setResultsBasedOnAssignment(usersResultsData);

    setSelectedCompetitionData(
      competitions.find(
        (competition: Competition | DocumentData) => competition.competitionId === selectedCompetitionId,
      ),
    );

    const availableTeams: string[] = selectedCompetitionData?.teams;
    setTeamsForCompetition(availableTeams?.length > 0 ? availableTeams : null);

    setUsersData(_usersData);
    setUsersTeamData(_usersData.filter((user) => user.team === selectedCompetitionData?.teams[0]));
    setSelectedTeam(_usersData[0].team);
  }, [competitions, selectedCompetitionData?.teams, selectedCompetitionId]);
  /**
	 * 
  /**
   * Set the associated teams when a competition is selected
   * Fetch userData for competition
   */
  useEffect(() => {
    if (selectedCompetitionId) {
      const availableTeams: string[] = competitions.find((comp) => comp.competitionId === selectedCompetitionId)?.teams;
      setTeamsForCompetition(availableTeams?.length > 0 ? availableTeams : null);
      setSelectedTeam('');
    }
    getUsersData();
  }, [selectedCompetitionId, competitions, getUsersData]);

  /**
   * Get average number, eg. for score or days
   *
   * @param score score or days
   * @param participants number of participants
   * @returns rounded number average
   */
  const getAverage = (score: number, participants: number): number => {
    return score && participants ? Math.round(score / participants) : 0;
  };

  const { totalPoints, totalDays } = usersData.reduce(
    (acc, currentValue) => {
      acc.totalPoints += currentValue.points;
      acc.totalDays += currentValue.days;
      return acc;
    },
    { totalPoints: 0, totalDays: 0 },
  );

  const { totalTeamPoints, totalTeamDays } = usersTeamData.reduce(
    (acc, currentValue) => {
      acc.totalTeamPoints += currentValue.points;
      acc.totalTeamDays += currentValue.days;
      return acc;
    },
    { totalTeamPoints: 0, totalTeamDays: 0 },
  );

  const handleDatabaseOperations = async (data: FieldValues) => {
    try {
      if (data.newTeamName !== '') {
        updateCompetitionTeamsFirebase({
          competitionId: selectedCompetitionId,
          teams: [...selectedCompetitionData?.teams, DOMPurify.sanitize(data.newTeamName)],
        });
      }
    } catch (error) {}
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (data.newTeamName === '') {
      setError('team', {
        type: 'manual',
        message: t('error_select_team') || 'Error',
      });
    } else {
      await handleDatabaseOperations(data);
      // setShowCreateNewTeam(false);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      tabIndex: 0,
    };
  }

  function isObjectEmpty(resultsBasedOnAssignment: {}) {
    return Object.keys(resultsBasedOnAssignment).length === 0;
  }

  /**
   * Add assignments to db
   */
  const addAssignments = () => {
    assignments.forEach(async (assignment, i) => {
      try {
        addAssignmentFirebase(assignment);
      } catch (e) {
        dispatch(setErrorMsg(t('error_text_general')));
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: !isDarkMode && isDesktop ? 'white' : 'background.default',
          borderBottom: isDesktop ? '1px solid' : 'none',
          borderColor: 'custom.paperBorder',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 8,
            pb: 0,
            pl: 68,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormControl variant="outlined">
              <Select
                labelId="choose-competition-label"
                id="choose-competition-select"
                value={selectedCompetitionId}
                onChange={handleCompetitionChange}
                sx={{ bgcolor: 'background.paper', minWidth: 320 }}
              >
                {competitions.map((competition) => (
                  <MenuItem key={competition.competitionId} value={competition.competitionId}>
                    {competition.competitionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              data-testid="button-create-competition"
              variant="contained"
              size="medium"
              sx={{ mb: 1, mt: 4 }}
              onClick={() => {
                navigate('/app/skapa-utmaning');
              }}
            >
              {t('button_start_new_competition')}
            </Button>
          </Box>

          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="meny">
            <Tab label={t('tab_overview')} {...a11yProps(0)} />
            <Tab label={t('tab_teams')} {...a11yProps(1)} disabled={selectedCompetitionData?.teams.length < 1} />
            <Tab label={t('tab_assignments_results')} {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ ml: 66, p: 4 }}>
        {tabValue === 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
            <ScoresPanel
              totalPoints={totalPoints}
              totalDays={totalDays}
              averageScore={getAverage(totalPoints, usersData.length)}
              averageDays={getAverage(totalPoints, usersData.length)}
            />
            <SortableTable data={usersData} />
          </Box>
        )}

        {tabValue === 1 && (
          <>
            {showCreateNewTeam && (
              <SimpleCard
                styles={{
                  mb: 4,
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                    <FormControl variant="outlined">
                      <Controller
                        control={control}
                        name="newTeamName"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                              {t('label_new_team')}
                            </label>
                            <OutlinedInput
                              data-testid="input-join-competition-new-team-name"
                              sx={{ bgcolor: 'background.paper', minWidth: 400 }}
                              value={value}
                              onChange={onChange}
                              name={name}
                              type="text"
                              required={true}
                              id={name}
                              autoComplete="off"
                              error={!!errors.newTeamName}
                            />
                            {errors.nickName && typeof errors.nickName.message === 'string' && (
                              <FormHelperText sx={{ ml: 0 }} error={true}>
                                {errors.nickName.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>

                    <Button
                      data-testid="button-add-team"
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      {t('button_create_new_team')}
                    </Button>
                  </Box>
                </form>
              </SimpleCard>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
              <FormControl variant="outlined">
                <Select
                  labelId="choose-team-label"
                  id="choose-team-select"
                  value={selectedTeam}
                  defaultValue=""
                  onChange={handleTeamChange}
                  sx={{ bgcolor: 'background.paper', minWidth: 180 }}
                  disabled={!teamsForCompetition}
                >
                  {teamsForCompetition?.map((team, i) => (
                    <MenuItem key={`${team}-${i}`} value={team}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!showCreateNewTeam && (
                <Button
                  data-testid="button-show-add-team"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => setShowCreateNewTeam(true)}
                >
                  {t('button_create_new_team')}
                </Button>
              )}
            </Box>

            <ScoresPanel
              totalPoints={totalTeamPoints}
              totalDays={totalTeamDays}
              averageScore={getAverage(totalTeamPoints, usersTeamData.length)}
              averageDays={getAverage(totalTeamDays, usersTeamData.length)}
            />

            <SortableTable data={usersTeamData} />
          </>
        )}

        {tabValue === 2 && (
          <SimpleCard>
            {!isObjectEmpty(resultsBasedOnAssignment) && !isObjectEmpty(fetchedAssignments) && (
              <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap', p: 4 }}>
                {Object.keys(resultsBasedOnAssignment).map((key: any, i: number) => {
                  const assignment = fetchedAssignments[key] as { title: { [key: string]: string } };

                  if (assignment && 'title' in assignment && 'color' in assignment) {
                    return (
                      <SimpleCard
                        key={i}
                        styles={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 240,
                          borderColor: assignment.color,
                          border: '2px solid',
                        }}
                      >
                        <Typography variant="body1" fontWeight={700}>
                          {assignment.title[lang]}
                        </Typography>
                        {/* @ts-ignore */}
                        <Typography variant="body2">{resultsBasedOnAssignment[key]}</Typography>
                      </SimpleCard>
                    );
                  }

                  return null; // or any other appropriate handling for cases where assignment is falsy or doesn't have a 'title' property
                })}
                <SimpleCard styles={{ display: 'flex', flexDirection: 'column', width: 240 }}>
                  <Typography variant="body1" fontWeight={700}>
                    Custom
                  </Typography>
                  {/* @ts-ignore */}
                  <Typography variant="body2">{resultsBasedOnAssignment.custom}</Typography>
                </SimpleCard>
              </Box>
            )}
            <Button variant="contained" sx={{ mt: 6 }} onClick={addAssignments}>
              Add assignments to DB
            </Button>
          </SimpleCard>
        )}
      </Box>
    </>
  );
};

export default AdminScreen;
